import React, { useState } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';


const Blog = () => {
    
    return <StaticQuery query={graphql`
    {   
        wordpressPage(title: { eq: "Updates" }){
            acf {
                blog_title
                read_more_button
            }
        }
        allWordpressPost(limit: 6 ){
            edges{
              node{
                id
                excerpt
                wordpress_id
                title
                slug
                featured_media {
                    source_url
                }
                tags {
                    id
                    name
                }
              }
            }
          }
    }
    `} render={props => (
        <div className="blog-page">
            <div className="frame">
                <div className="blog-title main-deliva-subtitle">{ props.wordpressPage.acf.blog_title }</div>
                <div className="blog-wrapper">
                    {
                        props.allWordpressPost.edges.map((post, index) => (
                        (index !== 0) ? (
                        <div className="item" key={post.node.id}>
                            <Link to={ `/post/${post.node.slug}`}>
                                <div className="item-wrapper">
                                    <img src={ post.node.featured_media.source_url } alt="" />
                                    <div className="post-title" dangerouslySetInnerHTML = {{ __html: post.node.title }}></div>
                                </div>
                            </Link>
                        </div> ) : null      
                        ))
                    }
                </div>
                <div className="blog-single-section" id="blogsingle">
                    {
                        <div className="item" key={props.allWordpressPost.edges[0].node.id}>
                            <div className="item-left">
                                <img src={ props.allWordpressPost.edges[0].node.featured_media.source_url } alt="" />
                            </div>
                            <div className="item-right">
                                <div className="post-title" dangerouslySetInnerHTML = {{ __html: props.allWordpressPost.edges[0].node.title }}></div>
                                <div className="post-excerpt" dangerouslySetInnerHTML = {{ __html: props.allWordpressPost.edges[0].node.excerpt }}></div>
                                <Link to={ `/post/${props.allWordpressPost.edges[0].node.slug}`} className="read-more">{ props.wordpressPage.acf.read_more_button }</Link>
                                { props.allWordpressPost.edges[0].node.tags ? (
                                <div className="tags">
                                    {
                                        props.allWordpressPost.edges[0].node.tags.map(tag => (
                                            <div className="tag" key={tag.id}>
                                                {  tag.name }
                                            </div>   
                                        ))
                                    }
                                </div> ) : null
                                }
                            </div>
                        </div>   
                    }
                </div>
            </div>
        </div>
    ) } />
};

export default Blog;