import React, {Component} from 'react'
import DefaultTemplate from "../templates/default"
import Blog from "../components/blog/blog"
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogPage extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render() {
    return <DefaultTemplate>
                <Blog />
            </DefaultTemplate>
    }
}

export default BlogPage
   